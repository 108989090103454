.hero-section {
    background: url('/public/home-banner/RectangleHero.png');
    background-size: cover;
    background-position: center;
}

@media (max-width: 1199px) {
    .hero-section {
        background: none; 
        background-color: #2d81ba;
    }
}

.testimonial{
    background: url('/public/home-banner/vector8.png');
    background-size: cover;
    background-position: center;
    background-color: #76add2;
}